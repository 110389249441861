import antragsbearbeitung from "./antragsbearbeitung.svg";
import mittelabruf from "./mittelabruf.svg";
import registrieren from "./registrieren.svg";
import verwendungsnachweis from "./verwendungsnachweis.svg";
import zuwendungsbescheid from "./zuwendungsbescheid.svg";

export default {
  antragsbearbeitung,
  mittelabruf,
  registrieren,
  verwendungsnachweis,
  zuwendungsbescheid,
};
